@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

$white:    #fff !default;
$primary: #3e2b56 !default;

// Override default variables before the import

$body-bg: #EDEDED;
$body-color: #000000 !default;

$font-family-sans-serif:      "Inter", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
) !default;
// scss-docs-end spacer-variables-maps

$nav-link-padding-x:                0.5rem !default;

// scss-docs-start headings-variables
$headings-font-weight:        700 !default;
// scss-docs-end headings-variables

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           50rem !default;
// scss-docs-end btn-variables

// scss-docs-start input-btn-variables
$input-bg:                    #fff !default;
$input-btn-padding-y:         .45rem !default;
$input-btn-focus-box-shadow:  0 !default;

// scss-docs-end input-btn-variables

// scss-docs-start form-check-variables
$form-check-input-width:                  1.5em !default;
$form-check-input-border:                 2px solid #767676 !default;
$form-check-input-checked-bg-color:       #5DBC66 !default;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#fff' d='M19.5,8.4l-1.1-1.1c-0.3-0.3-0.8-0.3-1.1,0c0,0,0,0,0,0l-7.3,7.3l-3.4-3.4c-0.3-0.3-0.8-0.3-1.1,0c0,0,0,0,0,0l-1.1,1.1 c-0.3,0.3-0.3,0.8,0,1.1c0,0,0,0,0,0l5,5c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0l8.9-8.9C19.8,9.2,19.8,8.7,19.5,8.4 C19.5,8.4,19.5,8.4,19.5,8.4z'/></svg>") !default;
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#fff' d='M19.5,8.4l-1.1-1.1c-0.3-0.3-0.8-0.3-1.1,0c0,0,0,0,0,0l-7.3,7.3l-3.4-3.4c-0.3-0.3-0.8-0.3-1.1,0c0,0,0,0,0,0l-1.1,1.1 c-0.3,0.3-0.3,0.8,0,1.1c0,0,0,0,0,0l5,5c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0l8.9-8.9C19.8,9.2,19.8,8.7,19.5,8.4 C19.5,8.4,19.5,8.4,19.5,8.4z'/></svg>") !default;

// scss-docs-end form-check-variables

// required imports
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


// merge maps
//$theme-colors: map-merge($theme-colors, $custom-colors);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

:root {
  --bs-body-line-height: #{$line-height-sm};
}
.btn {
  --bs-btn-font-weight: 700;
  --bs-btn-font-family: Arial;
  text-transform: uppercase;
  min-width: 276px;
}
.btn-outline-primary {
  --bs-btn-bg: #{$white};
  --bs-btn-hover-border-color: #{$white};
}