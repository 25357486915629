/*Presentation*/
.page-presentation {
  background-image: url(../img/bg-hero-presentation.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-color: #3E2B56;
  color: #fff;
}
body {
  background: rgb(237,237,237);
  background: linear-gradient(180deg, rgba(62,43,86,1) 247px, rgba(237,237,237,1) 247px);
  background-repeat: no-repeat;
}
.hero {
  font-size: 18px;
}
.page-presentation main {
  min-height: calc(100vh - (34px + 113px));
  display: flex;
  flex-direction: column;
}
footer { 
  font-size: 0.9rem;
  color: #FFFFFF80;
}
.container-lg.no-social {
  max-width: 854px !important;
}
label[for]{
  cursor: pointer;
}
.container-options > div[class*="form-"] {
  max-width: 662px;
  margin: 0 auto;
}
.pasos {
  font-size: 0.9rem;
  color: #494949;
}
.pasos strong {
  color: #000;
}
.content-questions {
  max-width: 832px !important;
}
.box-encuesta { min-height: 380px;}
.content-questions h2 {
  font-size: 0.9rem;
  font-weight: 700;
  color: #3E2B56;
}
.content-questions .numero {
  background-color: #00A4FA1A;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: inline-block;
  color: #16474E;
  font-size: 0.9rem;
  line-height: 36px;
  text-align: center;
  margin-right: 10px;
}
.encuesta-pasos p {
  font-size: 0.9rem;
  color: #000;
  font-weight: 500;
}
.encuesta-pasos label {
  font-size: 0.8rem;
}
.container-encuenta {
  min-height: 480px;
}
textarea.form-control {
  min-height: 178px !important;
}
.text-copyright {
  color: #767676CC;
  font-weight: 500;
}
footer hr {
  border-color: #CCCCCC;
}
footer .v2 .nav {
  justify-content: center;
}
.fw-500 {
  font-weight: 500; 
}
.page-thankyou main p {
  font-size: 1.13rem;
}

@media (min-width: 1200px){
  .container-md {
    max-width: 556px !important;
  }
  .container-lg {
      max-width: 960px !important;
  }
  .container-xl {
    max-width: 1024px !important;
  }
  
  .container-lg.no-social .navbar-brand { max-width: 150px !important;}
}